import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import AxiosApiClient from '../utils/AxiosApiClient';
import LoadingSpinner from './LoadingSpinner';

import $ from "jquery";

function PowerYoga(){
	
	//using hooks
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
	const [groups, setGroups] = useState([]);
  
	// Initial call for user list items
	useEffect(() => {
		
		setIsLoading(true);
		
		if (isMounted) {
			
			console.log('[Hiit - useEffect] mounted');


			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchData = async () => {
				try {
				const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				await getBearerToken();
				const response = await makeRequestWithHeaders('get', '/api/avw_classes/Yoga', null, {});

				setApiData(response);
				} catch (error) {
				handleApiError(error);
				}
			};
		
			fetchData();
			
		}else {
	      setIsMounted(true);
	    }
	    
	}, [isMounted]);

    function setApiData(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK
				
				setGroups(response.data.avw_groups);
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				
			}else{//more errors
			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		console.log('[Hiit - useEffect] error: ',error + ' back-end api call error');

		setIsLoading(false);
    }

    // Toggle group classes
    const handleToggleClasses = (i) => {

		for (var j = 0; j < groups.length; j++){

			if(j !== i){
				hideGroupClasses(j);
			}
			
		}

		if($('.group_'+i+'_classes').hasClass('hide')){
			$('.group_'+i+'_classes').removeClass('hide');
			$('.group_'+i+'_classes_button').html('Close');

			$('#group_'+i).removeClass('bg-fafafa');
			$('#group_'+i).addClass('bg-avw-light-blue');
			$('#group_'+i).removeClass('b1-ddd');
			$('#group_'+i).addClass('b1-avw-blue');
		}else{
			hideGroupClasses(i);
		}
        // Remove focus from the button
        $('.group_'+i+'_classes_button').blur();
    };
	function hideGroupClasses(i){
		$('.group_'+i+'_classes').addClass('hide');
		$('.group_'+i+'_classes_button').html('Classes');

		$('#group_'+i).removeClass('bg-avw-light-blue');
		$('#group_'+i).addClass('bg-fafafa');
		$('#group_'+i).removeClass('b1-avw-blue');
		$('#group_'+i).addClass('b1-ddd');
	}

    // Toggle group classes
    const handleBookClasses = (i) => {
		var cr = 0;
    };

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase bb1-333 pb-5">Power Yoga</div>
					</div>
				</div>
			</div>

			{isLoading ? (
			<LoadingSpinner paddingClass="pt-10" />
			) : (
				groups.length === 0 ? (
					<div className="panel large pt-20l-10s">
						<div className="grid-x bg-fafafa b1-ddd p-20l-10s">	
							<div className="large-12 medium-12 small-12 cell pl-10l-0s">
								<div className="font-raleway font-x-large font-weight-700 txt-333 text-left-center-on-small uppercase">No Groups Exists.</div>
							</div>
						</div>
					</div>
				) : (
					groups.map((group_item, i) => (
					group_item.avw_classes.length > 0 ? (
						<div className="panel large pt-20l-10s">
							<div id={"group_"+i} className="grid-x bg-fafafa b1-ddd p-20l-10s">
								<div className="large-4 medium-4 small-12 cell hide-for-small-only">
									<div className="text-left pr-10 hide-for-small-only">
										<img src={group_item.image_url} alt="group image" width="100%" />
									</div>
								</div>
								<div className="large-6 medium-6 small-12 cell pl-10l-0s">
									<div className="font-raleway font-x-large font-weight-700 txt-333 text-left-center-on-small uppercase">{group_item.title}</div>
									<div className="text-center show-for-small-only pt-20">
										<img src={group_item.image_url} alt="group image" width="75%"/>
									</div>
									<div className="font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify pt-10">
									{group_item.description}
									</div>
								</div>
								<div className="large-2 medium-2 small-12 cell text-right-center-on-small">
									<div className="pt-20l-10s show-for-small-only"></div>
									<div>
										<Link to="" onClick={() => handleBookClasses(i)} className="button small uppercase" >Book</Link>
										<span className="pl-20 show-for-small-only"><Link to="" onClick={() => handleToggleClasses(i)}  className={"button small uppercase group_"+i+"_classes_button"} >Classes</Link></span>
									</div>
									<div className="pt-20l-10s hide-for-small-only">
										<Link to="" onClick={() => handleToggleClasses(i)} className={"button small uppercase group_"+i+"_classes_button"} >Classes</Link>
									</div>
								</div>
								
								{group_item.avw_classes.length === 0 ? (
								<div className={"large-12 medium-12 small-12 cell text-left-center-on-small pt-20 group_"+i+"_classes hide"}>
									<span className="font-raleway font-weight-700 txt-333 uppercase text-center">No Classes Exists</span>
								</div>
								) : (
								<div className={"group_"+i+"_classes bg-fff mt-20l-10s p-10 b1-avw-blue scrollable hide"}>
								{group_item.avw_classes.map((class_item, j) => (
									<div className="grid-x mb-10 pb-10 bb1-ccc">
										<div className="large-3 medium-3 small-12 cell hide-for-small-only">
											<div className="text-left pr-10 hide-for-small-only">
												<img src={class_item.image_url} alt="class image" width="100%" />
											</div>
										</div>
										<div className="large-9 medium-9 small-12 cell pl-10l-0s">
											<div className="font-raleway font-medium font-weight-700 txt-333 text-left-center-on-small uppercase">{class_item.title}</div>
											<div className="text-center show-for-small-only pt-20">
												<img src={class_item.image_url} alt="class image" width="50%"/>
											</div>
											<div className="font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify pt-10">
											{class_item.description}
											</div>
										</div>
									</div>
								))}
								</div>
								)}
							</div>
						</div>
					) : (<></>)
					))
				)
			)
			}

		</div>
	);
}

export default PowerYoga;