
import Home from './components/frontend/Home';
import Hiit from './components/frontend/Hiit';
import PowerYoga from './components/frontend/PowerYoga';
import About from './components/frontend/About';
import Contact from './components/frontend/Contact';
import Help from './components/frontend/Help';
import Testimonials from './components/frontend/Testimonials';

import Login from './components/frontend/auth/Login';
import Register from './components/frontend/auth/Register';
import ActivateAccount from './components/frontend/auth/ActivateAccount';
import ForgotPassword from './components/frontend/auth/ForgotPassword';
import ResetPassword from './components/frontend/auth/ResetPassword';

import MemberDashboard from './components/member/Dashboard';
import MemberProfile from './components/member/Profile';
import MemberSettings from './components/member/Settings';
import MemberPayments from './components/member/Payments';
import MemberPaymentManager from './components/member/PaymentManager';

import AdminDashboard from './components/admin/Dashboard';
import AdminProfile from './components/admin/Profile';
import AdminSettings from './components/admin/Settings';
import AdminUsers from './components/admin/Users';

const ActivateAccountWithId = ({ id }) => <ActivateAccount id={id} />;
const ResetPasswordWithIdEmail =  ({ id, email }) => <ResetPassword id={id} email={email} />;

const routes = [
	{
		path: '/',
		element: <Home />,
		type: 'public',
	}, {
		path: '/home',
		element: <Home />,
		type: 'public',
	}, {
		path: '/hiit',
		element: <Hiit />,
		type: 'public',
	}, {
		path: '/power_yoga',
		element: <PowerYoga />,
		type: 'public',
	}, {
		path: '/about',
		element: <About />,
		type: 'public',
	}, {
		path: '/contact',
		element: <Contact />,
		type: 'public',
	}, {
		path: '/help',
		element: <Help />,
		type: 'public',
	}, {
		path: '/testimonials',
		element: <Testimonials />,
		type: 'public',
	}, {
		path: '/login',
		element: <Login />,
		type: 'public',
	}, {
		path: '/register',
		element: <Register />,
		type: 'public',
	}, {
		path: '/activate_account/:id',
		element: <ActivateAccountWithId />,
		type: 'public',
	}, {
		path: '/forgot_password',
		element: <ForgotPassword />,
		type: 'public',
	}, {
		path: '/reset_password/:id/:email',
		element: <ResetPasswordWithIdEmail />,
		type: 'public',
	}, {
		path: '/member/dashboard',
		element: <MemberDashboard />,
		type: 'private member',
	}, {
		path: '/member/profile',
		element: <MemberProfile />,
		type: 'private member',
	}, {
		path: '/member/settings',
		element: <MemberSettings />,
		type: 'private member',
	}, {
		path: '/member/payments',
		element: <MemberPayments />,
		type: 'private member',
	}, {
		path: '/member/payment_manager',
		element: <MemberPaymentManager />,
		type: 'private member',
	}, {
		path: '/admin/dashboard',
		element: <AdminDashboard />,
		type: 'private admin',
	}, {
		path: '/admin/profile',
		element: <AdminProfile />,
		type: 'private admin',
	}, {
		path: '/admin/settings',
		element: <AdminSettings />,
		type: 'private admin',
	}, {
		path: '/admin/users',
		element: <AdminUsers />,
		type: 'private admin',
	},
	// And so on.

	//Tests
];

export default routes;