import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AxiosApiClient, { apiBackends, findBackend } from '../utils/AxiosApiClient';
import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';

import swal from 'sweetalert';

import edit_icon from '../../assets/frontend/images/edit_icon.png';

const error_style = 'font-raleway font-small font-weight-500 txt-000 bg-light-red p-5';

function Settings(){
	
	const navHistory = useNavigate();
	
	// using hooks
    const [isLoading, setIsLoading] = useState(false);
	const [settingsInput, setSettingsInput] = useState({
		api: '',
		setting2: '',
		setting3: '',
		info: '',
		errorList: [],
		errorStyle: [],
	});
    // Initial states
    const [isSettingsEdit, setSettingsEdit] = useState(false);
    const [disableSettings, setDisableSettings] = useState(true);
  
    // Function to handle edit
    const handleSettingsEdit = (i) => {
		
        // toggle edit mode
        setSettingsEdit(!isSettingsEdit);
			
		setSettingsInput({...settingsInput, info: '' });
    };
  
    // The handleInputChange handler can be set up to handle
    // many different inputs in the form, listen for changes 
    // to input elements and record their values in state
	const handleInput = (event) => {
		event.persist();
		
        setDisableSettings(false);
			
		setSettingsInput({...settingsInput, [event.target.name]: event.target.value });
		
		//remove the target error message no matter the new input, it will be validated on the server
		if (settingsInput.errorList.hasOwnProperty(event.target.name)){
			delete settingsInput.errorList[event.target.name];
			delete settingsInput.errorStyle[event.target.name];
		}
	}
  
    // Function to handle save
    const handleSaveSettings = () => {
		saveSettings();
    };

	function saveSettings(){
		
		setIsLoading(true);
			
		//values sent to api
		const payload = {
			api: settingsInput.api,
			setting2: settingsInput.setting2,
			setting3: settingsInput.setting3,
		}

		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
			auth_api = localStorage.getItem('auth_api');
		}

		const fetchData = async () => {
			try {
			  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
			  await getBearerToken();
			  const response = await makeRequestWithHeaders('post', '/api/save_settings', payload, {});

			  setApiData(response);
			} catch (error) {
			  handleApiError(error);
			}
		};
	  
		fetchData();
		/*
		axios.get('/sanctum/csrf-cookie').then(response_csrf => {// CSRF Protection through Laravel
			axios.post('/api/save_settings', payload, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
				}
			}).then(response =>{
				if(response.data.status === 200){//HTTP_OK
					console.log('saveSettings success: ',response.data);
	
					//update all state properties
					setSettingsInput({...settingsInput, settings: '', info: 'Settings Updated', errorList: [], errorStyle: [] });
			        setSettingsEdit(false);
			        setDisableSettings(true);
						
	            }else if(response.data.status === 401){//HTTP_UNAUTHORIZED
					//user not authenticated on server so remove from local storage
					AuthUtility.clearAuthData();
	            
					swal("Warning",response.data.message,"warning");
	                	
					navHistory('/login');
					
	            }else if(response.data.status === 404){//HTTP_NOT_FOUND
	                swal("Warning",response.data.message,"warning");
	            }else if(response.data.status === 422){//HTTP_UNPROCESSABLE_ENTITY
	                swal("Warning",response.data.message,"warning");
				}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
					
					var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
					Object.keys(errorStyleTemp).map((key) => (
						errorStyleTemp[key] = error_style
					));
					
					//validation errors mapped to input fields
					setSettingsInput({...settingsInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });
	
	            }else{//more errors
				}
				
				setIsLoading(false);
					
			}).catch(function (error) {
				console.log('saveSettings error: ',error + ' back-end api call error');
				
			});
		}).catch(function (error) {
			//csrf-cookie is outdated
			console.log('saveSettings error: ',error + ' csrf-cookie is outdated');
			
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
	                	
			setIsLoading(false);
			
			navHistory('/login');
					
		});
		*/
	}

    function setApiData(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('saveSettings success: ',response.data);

				//update all state properties
				localStorage.setItem('auth_api', response.data.api);
				setSettingsInput({...settingsInput, api: response.data.api, setting2: response.data.setting2, setting3: response.data.setting3, info: 'Settings Updated', errorList: [], errorStyle: [] });
				setSettingsEdit(false);
				setDisableSettings(true);
					
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
			
				swal("Warning",response.data.message,"warning");
					
				navHistory('/login');
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				swal("Warning",response.data.message,"warning");
			}else if(response.data.status === 422){//HTTP_UNPROCESSABLE_ENTITY
				swal("Warning",response.data.message,"warning");
			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
				
				var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
				Object.keys(errorStyleTemp).map((key) => (
					errorStyleTemp[key] = error_style
				));
				
				//validation errors mapped to input fields
				setSettingsInput({...settingsInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });

			}else{//more errors
			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		//csrf-cookie is outdated
		console.log('saveSettings error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
			
		setIsLoading(false);
		swal("Error",error,"error");
		navHistory('/login');
    }

	const handleCancelSettings = () => {
        setSettingsEdit(false);
        setDisableSettings(true);
		setSettingsInput({...settingsInput, settings: '', errorList: [], errorStyle: [] });
	}
  
	const formatBackendName = (backend) => {
		return backend
		  .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before capital letters preceded by a lowercase letter
		  .toLowerCase(); // Convert the entire string to lowercase
	};
	  

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-40">
		
			<div className="panel large pt-20">
				<div className="grid-x">
			
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway page-header font-weight-800 txt-slate-blue bb2-slate-blue letter-spacing-1px uppercase pb-5">Settings</div>
					</div>

					<div className="large-6 medium-6 small-12 cell text-left pt-20 pr-10">
						<table className="unstriped unbordered mb-0">
							<tbody>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">API:</td><td className="font-raleway font-weight-600 pb-20">{formatBackendName(localStorage.getItem('auth_api'))}</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Setting 2:</td><td className="font-raleway font-weight-600 pb-20">Coming Soon</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Setting 3:</td><td className="font-raleway font-weight-600 pb-20">Coming Soon</td></tr>
							</tbody>
						</table>
					</div>
					<div className="small-12 cell pt-20 show-for-small-only"></div>
					<div className="large-6 medium-6 small-12 cell pt-20">
						<div className="bg-fff b1-ddd p-20l-10s">
							{isSettingsEdit ? (
								<div className="grid-x">
									<div className="large-12 medium-12 small-12 cell text-center">
										<div className="font-raleway page-header font-weight-800  txt-slate-blue letter-spacing-1px underline uppercase pb-20">Change Settings</div>
									</div>
									<div className="large-12 medium-12 small-12 cell">
											<select name="api" onChange={handleInput} value={settingsInput.api}>
												{apiBackends.map((backend, index) => (
												<option key={index} value={backend}>
													{formatBackendName(backend)}
												</option>
												))}
											</select>
									</div>
									<div className="large-12 medium-12 small-12 cell">
										<div className={settingsInput.errorStyle.setting2}>{settingsInput.errorList.setting2}</div>
										<div className="input-group">
											<span className="input-group-label"><span className="fas fa-lock txt-555"></span></span>
											<input type="text" name="setting2" onChange={handleInput} placeholder="Enter Setting 2" />
										</div>
									</div>
									<div className="large-12 medium-12 small-12 cell">
										<div className={settingsInput.errorStyle.setting3}>{settingsInput.errorList.setting3}</div>
										<div className="input-group">
											<span className="input-group-label"><span className="fas fa-lock txt-555"></span></span>
											<input type="text" name="setting3" onChange={handleInput} placeholder="Enter Setting 3" />
										</div>
									</div>
									<div className="large-6 medium-6 small-6 cell text-left">
										<Link onClick={handleCancelSettings} className="button medium">CANCEL</Link>
									</div>
									<div className="large-6 medium-6 small-6 cell text-right">
						                {disableSettings ? (
											<Link disabled className="button medium disabled">SAVE</Link>
						                ) : (
											<Link onClick={handleSaveSettings} className="button medium">SAVE</Link>
						                )}
									</div>
								</div>
				        	) : (
				            <Link onClick={handleSettingsEdit} className="icon-with-text hover-opacity-50 no-underline">
				            	<img src={edit_icon} className="" width="20" alt="change settings"/>&nbsp;&nbsp;<span className="txt-dark-red uppercase">CHANGE SETTINGS</span>
				            </Link>
							)}
							{settingsInput.info &&
								<div className="font-raleway font-weight-600 pt-20">{settingsInput.info}</div>
							}
						</div>
						<div className="text-center">
							{isLoading && 
							<div className="text-center">
							<LoadingSpinner paddingClass="p-10" />
							</div>
							}
						</div>
					</div>
				</div>
			</div>
			
		</div>
	);
}

export default Settings;