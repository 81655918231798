import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate, useParams} from 'react-router-dom';

import AxiosApiClient from '../../utils/AxiosApiClient';
import AuthUtility from './AuthUtility';
import LoadingSpinner from '../LoadingSpinner';

//import axios from 'axios';
import swal from 'sweetalert';

function ActivateAccount(){

	const navHistory = useNavigate();

	// using hooks
    const [isLoading, setIsLoading] = React.useState(false);
    const [isMounted, setIsMounted] = useState(false);
	const [isActivated, setIsActivated] = useState(false);
	const [navPath, setNavPath] = useState('');
	const [usersName, setUsersName] = useState('');
	
	const { id } = useParams();
  
	var OnCanvasNavigationComponent;

	//initial call to activate account
	useEffect(() => {
		
		setIsLoading(true);
		
		if (isMounted) {
	
			//values sent to api
			const payload = {
				random_id: id,
			}

			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchData = async () => {
				try {
				  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				  await getBearerToken();
				  const response = await makeRequestWithHeaders('put', '/api/activate_account/'+id, null, {});
	
				  setApiData(response);
				} catch (error) {
				  handleApiError(error);
				}
			};
		  
			fetchData();
			
		}else {
	      setIsMounted(true);
	    }
		
	}, [isMounted, id, navHistory]);

    function setApiData(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
			
				AuthUtility.setAuthData(response.data.auth_first_name, response.data.auth_last_name, 
				response.data.auth_email, response.data.auth_token, response.data.auth_role, null, null);

				setUsersName(localStorage.getItem('auth_first_name') + ' ' + localStorage.getItem('auth_last_name'));

				
				if(localStorage.getItem('auth_role') === 'member'){
					setNavPath('/member/dashboard');
				}else if(localStorage.getItem('auth_role') === 'admin'){
					setNavPath('/admin/dashboard');
				}

				setIsActivated(true);
					
			}else if(response.data.status === 401 || response.data.status === 404){//HTTP_UNAUTHORIZED OR HTTP_NOT_FOUND
			
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
			
				setIsLoading(false);
				swal("Warning",response.data.message + ' Can not activate account. Please click on the link in your email after registering.',"warning");
				navHistory('/login');

			}else{//more errors

			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		//csrf-cookie is outdated
		console.log('[ActivateAccount] error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
			
		setIsLoading(false);
		swal("Error",error,"error");
		navHistory('/login');
    }

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase bb1-333 pb-5">Activate Account</div>
					</div>
				</div>
			</div>

			{isLoading ? (
			<LoadingSpinner paddingClass="pt-10" />
			) : (
		
				<div className="panel large pt-20l-10s">
					<div className="grid-x">
						{isActivated && 
						<div className="large-12 medium-12 small-12 cell text-left">
							<table className="unstriped unbordered mb-0">
								<tbody>
								<tr><td className="font-raleway font-medium font-weight-500 width-75px pb-20" valign="top">Name:</td><td className="font-raleway font-medium font-weight-700 pb-20" valign="top">{usersName}</td></tr>
								<tr><td className="font-raleway font-medium font-weight-500 width-75px pb-20" valign="top">Email:</td><td className="font-raleway font-medium font-weight-700 pb-20" valign="top">{localStorage.getItem('auth_email')}</td></tr>
								<tr><td className="font-raleway font-medium font-weight-500 width-75px pb-20" valign="top">Role:</td><td className="font-raleway font-medium font-weight-700 pb-20" valign="top">{localStorage.getItem('auth_role')}</td></tr>
								<tr><td className="font-raleway font-medium font-weight-500 width-75px" valign="top">Status:</td><td className="font-raleway font-medium font-weight-700" valign="top">active</td></tr>
								</tbody>
							</table>
							<div className="text-center pt-40">
								<Link to={navPath} className="button width-125px uppercase">DASHBOARD</Link>
							</div>
						</div>
						}
					</div>
				</div>
			)}

		</div>
	);
}

export default ActivateAccount;